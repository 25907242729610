exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-laust-johan-deleuran-tsx": () => import("./../../../src/pages/about-laust-johan-deleuran.tsx" /* webpackChunkName: "component---src-pages-about-laust-johan-deleuran-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-work-tsx": () => import("./../../../src/pages/my-work.tsx" /* webpackChunkName: "component---src-pages-my-work-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../../../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-resume-laust-johan-deleuran-tsx": () => import("./../../../src/pages/resume-laust-johan-deleuran.tsx" /* webpackChunkName: "component---src-pages-resume-laust-johan-deleuran-tsx" */),
  "component---src-templates-work-post-tsx": () => import("./../../../src/templates/work-post.tsx" /* webpackChunkName: "component---src-templates-work-post-tsx" */)
}

